import BaseModal from '@material-ui/core/Modal';
import React, { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import Button, {
  ButtonShape,
  ButtonSize,
  ButtonVariants,
} from '@/components/switchback/Button/Button';
import { ArrowVariants } from '@/components/switchback/Carousel/Arrow';
import Carousel, { IProps as ICarouselProps } from '@/components/switchback/Carousel/Carousel';
import { CLOSE } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';

import css from './Lightbox.module.css';

const backdropProps = {
  'data-testid': 'lightbox-backdrop',
  style: {
    backgroundColor: 'hsla(0, 0%, 0%, 0.9)',
  },
};

enum LightboxSize {
  normal = 'normal',
  full = 'full',
}

interface IProps extends Omit<ICarouselProps, 'componentName'> {
  /**
   * Controls if the lightbox is open.
   */
  open: boolean;
  /**
   * Controls the width of the lightbox content
   */
  size?: LightboxSize;
  /**
   * Content of the lightbox.
   */
  children: React.ReactNode;
  /**
   * Handler for closing the lightbox when clicking on the backdrop or close button.
   */
  onClose: () => void;
  customModalClass?: string;
  disableEnforceFocus?: boolean;
  disableAutoFocus?: boolean;
}

const Lightbox: React.FC<IProps> = ({
  open,
  size = LightboxSize.normal,
  children,
  onClose,
  customModalClass,
  disableEnforceFocus,
  disableAutoFocus,
  ...rest
}) => {
  const intl = useIntl();
  const slider = useRef<any>(null);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => {
      slider.current && slider.current.keyBoardNavigation(e);
    },
    [slider],
  );

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [open, handleKeyDown]);

  return (
    <BaseModal
      open={open}
      BackdropProps={backdropProps}
      onClose={onClose}
      className={customModalClass}
      disableEnforceFocus={disableEnforceFocus}
      disableAutoFocus={disableAutoFocus}
      style={{ zIndex: 1460 }}>
      <div>
        <div className="absolute z-10 top-3 right-3 md:top-8 md:right-8">
          <Button
            aria-label={intl.formatMessage({ defaultMessage: 'Close gallery', id: '/q2ubh' })}
            data-testid="lightbox-close-btn"
            shape={ButtonShape.circle}
            variant={ButtonVariants.whiteOutlined}
            size={ButtonSize.small}
            onClick={onClose}>
            <Icon name={CLOSE} size={IconSize.normal} />
          </Button>
        </div>
        <div
          data-size={size}
          className={`absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 ${css.slideContainer}`}>
          <Carousel
            ref={slider}
            lightbox
            componentName="lightbox"
            dots
            draggable={false}
            infinite={false}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={[false, true, true]}
            arrowVariant={ArrowVariants.outside}
            dotsClass={css.dots}
            customPaging={index => (
              <button
                aria-label={intl.formatMessage(
                  { defaultMessage: 'Go to slide {slide}', id: 'SV/+if' },
                  { slide: index + 1 },
                )}
                className="w-2 h-2 mr-3 bg-white rounded-full"
                onClick={() => slider.current?.slickGoTo(index)}
              />
            )}
            {...rest}>
            {children}
          </Carousel>
        </div>
      </div>
    </BaseModal>
  );
};

export default React.memo(Lightbox);
